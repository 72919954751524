<template>
  <div class='email'>
    <layout-header />
    <main class='email__main base-content-pc'>
      <section class='email__main__left'>
        <select-bar-setting />
      </section>
      <section class='email__main__right'>
        <div class='email__main__right__title'>
          <h3>メールアドレス変更</h3>
          <button-normal
            @emitClick='execChangeEmail()'
            :disabled='judgeDisabledUpdateButton'
            :loading='isLoading.changeEmail'
          >
            <p>認証メールを送信</p>
          </button-normal>
        </div>
        <form class='email__main__right__input'>
          <h5>新しく設定するメールアドレス</h5>
          <input-email
            :errorMessage='errorMessage.email'
            @emitInputText='setEmail'
          />
        </form>
      </section>
    </main>
    <layout-footer />
  </div>
</template>
<script>
import LayoutHeader from '@/components/layouts/LayoutHeader.vue';
import LayoutFooter from '@/components/layouts/LayoutFooter.vue';
import ButtonNormal from '@/components/atoms/button/ButtonNormal.vue';
import InputEmail from '@/components/atoms/input/InputEmail.vue';
import SelectBarSetting from '@/components/atoms/select/SelectBarSetting.vue';

export default {
  components: {
    LayoutHeader,
    LayoutFooter,
    ButtonNormal,
    InputEmail,
    SelectBarSetting,
  },
  data() {
    return {
      isLoading: {
        changeEmail: false,
      },
      email: null,
      errorMessage: {
        email: null,
      },
      rule: {
        email: {
          reg: /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]*\.[A-Za-z0-9]+$/,
        },
      },
    };
  },
  computed: {
    judgeDisabledUpdateButton() {
      // エラーメッセージ判定
      if (this.errorMessage.email !== '') return true;
      if (this.email === '') return true;
      return false;
    },
  },
  methods: {
    showNoticeBar(title, type, text) {
      this.$notify({
        title,
        text,
        type,
        speed: 400,
        group: 'top-right',
      });
    },
    setEmail(email) {
      this.email = email;
    },
    async execChangeEmail() {
      try {
        this.isLoading.changeEmail = true;
        await this.$store.dispatch('changeEmail', this.email);
        this.isLoading.changeEmail = false;
        this.showNoticeBar(
          '認証メールを送信しました',
          'success',
        );
      } catch (e) {
        this.showNoticeBar(
          'メールアドレス変更に失敗しました',
          'error',
          e.message,
        );
        this.isLoading.changeEmail = false;
      }
    },
  },
  watch: {
    // リアルタイムバリデーション
    email: function updateEmail(to) {
      if (to !== null) {
        if (!to.match(this.rule.email.reg)) {
          this.errorMessage.email = 'メール形式で入力する必要があります';
        } else {
          this.errorMessage.email = '';
        }
      }
    },
  },
};
</script>
<style lang='scss'>
.email {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.email__main {
  margin: 0 auto 120px;
  display: flex;
  max-width: 100%;
}

.email__main__left {
  width: 25%;
  margin-right: 30px;
}

.email__main__right {
  width: 75%;
  background-color: $color-white;
  padding: 30px;
}

.email__main__right__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
}

.email__main__right__input,
.email__main__right__introduction {
  h5 {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 5px;
  }
}

.email__main__right__input {
  margin-bottom: 5px;
}

.email__main__right__delete {
  font-size: 12px;
  margin-top: 15px;
  color: $color-dark-gray;
  text-decoration: none;
}

.email__main__right__delete:hover {
  text-decoration: underline;
}
</style>
